<template>
  <default-layout>
    <div class="container directory--container">
      <div class="row">
        <div class="col-12 col-md-8 left--col">
          <h1 class="directory--title mb-3">{{ propertyType.name }} {{ $t('directory.title') }}</h1>
          <district></district>
          <div class="row mt-4" v-if="propertyType">
            <directory-listing
              class="col-12 col-md-12 directory--listing-wrapper"
              listingType="R"
              :propertyType="propertyType"
            ></directory-listing>
            <!--            <directory-listing-->
            <!--              class="col-12 col-md-6 directory&#45;&#45;listing-wrapper"-->
            <!--              listingType="S"-->
            <!--              :propertyType="propertyType"-->
            <!--            ></directory-listing>-->
          </div>
        </div>
        <div class="col-12 col-md-4 right--col search--filters">
          <h2 class="directory--title mb-3">
            {{ $t('directory.search.title') }} {{ propertyType.name }}
          </h2>
          <district-filter></district-filter>
          <building-size :changeRoute="false"></building-size>
          <hr />
          <button class="btn btn-primary btn-block mt-2" @click="openSearchPage">
            {{ $t('directory.search.searchBtn') }}
          </button>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
const District = () => import('@/components/directory/district');
const DirectoryListing = () => import('@/components/directory/listing');
const DistrictFilter = () => import('@/components/directory/district-filter');
const BuildingSize = () => import('@/components/search/partials/filters-sidebar/building-size');
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapState } from 'vuex';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    District,
    DirectoryListing,
    DistrictFilter,
    BuildingSize,
  },
  computed: {
    ...mapState({
      headerHeight: (state) => state.size.headerHeight,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
      selectedDistrict: (state) => state.v2.directory.selectedDistrict,
      buildingSizeMin: (state) => state.v2.search.buildingSizeMin,
      buildingSizeMax: (state) => state.v2.search.buildingSizeMax,
    }),
  },
  data: () => ({
    propertyType: {},
  }),
  async fetch({ store, params, route }) {
    this.propertyType = await store.dispatch(
      'v2/masters/getPropertyTypeByName',
      params.propertyType,
    );

    let propertyTypeName = params.propertyType;
    try {
      await store.dispatch('v2/directory/getDirectoryData', propertyTypeName);
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (propertyTypeName === 'coworkingspace') propertyTypeName = 'Co-working Space';
    const baseUrl = store.state.global.baseUrl;
    const title = 'Rentfix.com - Direktori ' + propertyTypeName.toLowerCase() + ' | Rentfix';
    const description =
      'Rentfix.com menyediakan properti dengan tipe ' +
      propertyTypeName.toLowerCase() +
      ' dan membantu Anda melakukan proses sewa menyewa properti di seluruh Indonesia. | Rentfix';
    const head = {
      title: title,
      meta: [
        { hid: 'og-url', property: 'og:url', content: baseUrl + route.fullPath },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        {
          hid: 'og-title',
          property: 'og:title',
          content: title,
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'Rentfix' },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  methods: {
    openSearchPage() {
      let district = this.selectedDistrict;
      let listingType = 'R';
      let searchRoute = '';
      let propertyType = this.$route.params.propertyType;
      if (propertyType === 'coworkingspace') propertyType = 'Co-working Space';
      if (district && district.name) {
        searchRoute = encodeURI(
          '/' +
            (listingType === 'R' ? 'sewa' : 'beli') +
            '/search/' +
            propertyType +
            '/' +
            district.name.replace('-', '__').split(' ').join('-').replace(/\//g, '') +
            '?category=city&category_filter_id=' +
            district.id,
        );
        if (this.buildingSizeMin) searchRoute += '&building_size_min=' + this.buildingSizeMin;
        if (this.buildingSizeMax) searchRoute += '&building_size_max=' + this.buildingSizeMax;
      } else {
        searchRoute = encodeURI(
          '/' + (listingType === 'R' ? 'sewa' : 'beli') + '/search/' + propertyType,
        );
        if (this.buildingSizeMin) searchRoute += '?building_size_min=' + this.buildingSizeMin;
        if (this.buildingSizeMax) searchRoute += '&building_size_max=' + this.buildingSizeMax;
      }
      this.$router.push(searchRoute);
    },
  },
};
</script>
